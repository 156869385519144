.meting_box{
   display: flex;
   align-items: center;
   justify-content:center ;
   position: absolute;
   height: 100%;
   width: 100%;
  
}
.meeting_sub_box{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 15px;
    border-radius: 10px;
    width: 31%;
}
input.formcontroller {
    width: 100%;
    border: 1px solid #bbb;
    padding: 10px;
    border-radius: 5px;
    /* margin: 10px 5px; */
}
.meeting-join{
    background-color: black;
    color: #fff;
    border-radius: 10px;
    margin: 15px 0px;
}
.titlelabele{
    margin: 10px 0px;
}
.meeting-join.disabled {
    background-color: lightgray;
    cursor: not-allowed;
  }
